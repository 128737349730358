import { mapGetters } from "vuex";
import {
  startTestAgent,
  getagentdetails,
  getTestTimer,
  getSystemInfo
} from "../apiFunction.js";
import Spinner from "../components/fixedcomponents/Spinner.vue";
import postMessageUrl from "./postMessageUrl.js";

const dosDonts = {
  name: "DoAndDont",
  mixins: [postMessageUrl],
  components: {
    Spinner
  },
  data: () => ({
    startButton: false,
    checked: false,
    Loaderstatus: false,
    locationpermission: false,
    notChecked:false,
    agentdata: {}
  }),
  created() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    this.askCameraPermission();
    if (!("geolocation" in navigator)) {
      this.errorStr = "Geolocation is not available.";
      return;
    }
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        this.locationpermission = true;
        this.location = pos;
      },
      (err) => {
        this.locationpermission = false;
        this.errorStr = err.message;
      },
      { enableHighAccuracy: true }
    );
  },
  mounted() {
    this.Loaderstatus = true;
    setTimeout(() => {
      this.Loaderstatus = false;
    }, 1000);
    let timeset = Number(this.testDetails.config.testDuration) * 60;
    getTestTimer(
      this.testDetails.testID,
      this.testDetails.config.configID,
      this.testDetails.groupID,
      this.testDetails.attemptNo
    )
      .then((response) => {
        if (response.data.data.timeLeft != 0) {
          localStorage.setItem("timer", Number(response.data.data.timeLeft));
        } else {
          localStorage.setItem("timer", timeset);
        }
      })
      .catch(() => {
        localStorage.setItem("timer", timeset);
      });
    localStorage.setItem("timer", timeset);
    localStorage.setItem("doDont", true);
    window.addEventListener("message", this.windowListenerFunc, false);
  },
  computed: {
    ...mapGetters("sectionVUEX", {
      time: "getTime",
      testDetails: "getTestDetails",
      userID: "getUserID",
      uuid: "getUUID",
      licenseKey: "getlicensekey",
      statsLocation: "getStatsLocation",
      userDetials: "getUserDetails"
    })
  },
  methods: {
    async reload() {
      window.location.reload();
    },
    candidateteststatsPost() {
      try {
        let geolocation = {
          latitude: JSON.stringify(this.location.coords.latitude),
          longitude: JSON.stringify(this.location.coords.longitude)
        };
        this.$store.commit("sectionVUEX/savestatsLoaction", geolocation);
        this.locationpermission = true;
      } catch (error) {
        this.locationpermission = false;
      }
    },
    askCameraPermission() {
      navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true
      });
    },

    async windowListenerFunc(event) {
      if (event.source != window) {
        return;
      }
      if (event.data.type == "START_TEST_RESPONSE") {
        let extensionData = event.data.permission;
        if (extensionData && this.testDetails.config.allowAgent) {
          let agentdata = await this.startAgent();
          this.goToTestDetails(agentdata, extensionData);
        } else {
          this.goToTestDetails({ permission: true }, extensionData);
        }
      }
      if (event.data.type == "EXTENTION_IS_CONFIGRED") {
        let status = {
          type: "EXTENTION_IS_CONFIGRED_RESPONSE",
          permission: this.testDetails.config.allowExtension
        };
        window.postMessage(status, this.url);
      }
    },
  
    async startTestBtn() {
      this.Loaderstatus = true;
      this.candidateteststatsPost();

      const isCameraGranted = await this.checkPermissionGranted("camera");
      const isMicroGranted = await this.checkPermissionGranted("microphone");

      if (!isCameraGranted || !isMicroGranted) {
        this.showLocationModal();
      } else if (this.locationpermission === true) {
        await this.handleTestDetailsConfig();
      } else {
        this.showLocationModal();
      }
    },

    async handleTestDetailsConfig() {
      localStorage.removeItem("doDont");

      if (
        this.testDetails.config.allowExtension &&
        this.testDetails.config.allowAgent
      ) {
        await this.handleExtensionAndAgent();
      } else if (this.testDetails.config.allowExtension) {
        await this.handleExtensionOnly();
      } else if (this.testDetails.config.allowAgent) {
        await this.handleAgentOnly();
      } else {
        this.goToTestDetails({ permission: true }, true);
      }
    },

    async handleExtensionAndAgent() {
      let agentRes = await this.systemCheckAgent();

      if (agentRes === "err") {
        this.showAgentModal();
      } else if (agentRes.agentPermission) {
        await this.checkExtensionAndPostMessage();
      } else {
        this.handleAgentPermissionFalse(agentRes);
      }
    },

    async handleExtensionOnly() {
      await this.checkExtensionAndPostMessage();
    },

    async handleAgentOnly() {
      let agentRes = await this.systemCheckAgent();
      if (agentRes === "err") {
        this.showAgentModal();
      } else if (agentRes.agentPermission) {
        let agentdata = await this.startAgent();
        this.goToTestDetails(agentdata, true);
      } else {
        this.handleAgentPermissionFalse(agentRes);
      }
    },

    async checkExtensionAndPostMessage() {
      const checkExtension = (id, src, callback) => {
        let e = new Image();
        e.onload = () => callback(1);
        e.onerror = () => callback(0);
        e.src = "chrome-extension://" + id + "/" + src;
      };
      checkExtension(
        "gfepljemgkegiccegjbclmhmnodpieoj",
        "images/icon.png",
        (ok) => {
          if (ok === 1) {
            this.postMessage({ type: "START_TEST", text: "START_TEST" });
          } else {
            this.Loaderstatus = false;
            this.showExtensionModal();
          }
        }
      );
    },

    handleAgentPermissionFalse(agentRes) {
      if (!agentRes.displayCheck) {
        this.showDisconnectedModal();
      } else if (!agentRes.vmCheck) {
        this.showVmMachineModal();
      } else {
        this.showSomeProblemModal();
      }
      this.Loaderstatus = false;
    },

    showLocationModal() {
      this.$bvModal.show("locationmodal");
      this.Loaderstatus = false;
    },

    showAgentModal() {
      console.log("agentmodal");
      this.$bvModal.show("agentmodal");
      this.Loaderstatus = false;
    },

    showDisconnectedModal() {
      this.$bvModal.show("devdisconected");
      this.Loaderstatus = false;
    },

    showVmMachineModal() {
      this.$bvModal.show("vmachine");
      this.Loaderstatus = false;
    },

    showSomeProblemModal() {
      this.$bvModal.show("someprob");
      this.Loaderstatus = false;
    },

    showExtensionModal() {
      this.Loaderstatus = false;
      this.$bvModal.show("extmodal");
    },

    postMessage(data) {
      window.postMessage(data, this.url);
    },

    async checkPermissionGranted(key) {
      try {
        const permission = await navigator.permissions.query({ name: key });
        return permission.state === "granted";
      } catch (error) {
        return false;
      }
    },
    systemCheckAgent() {
      return new Promise((resolve) => {
        getagentdetails(this.testDetails.attemptNo)
          .then((res) => {
            this.agentdata = res;
            resolve(res.data);
          })
          .catch((err) => {
            console.log(err);
            resolve("err");
          });
      });
    },
    startAgent() {
      return new Promise((resolve) => {
        let agentdata = {
          testID: this.testDetails.testID,
          topicID: this.testDetails.topicID,
          groupID: this.testDetails.groupID,
          userID: this.userID,
          licenseKey: this.licenseKey,
          attemptNo: this.testDetails.attemptNo
        };
        startTestAgent(agentdata)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            console.log("ERROR" + err);
          });
      });
    },

    async goToTestDetails(agentdata, extensionData) {
      this.Loaderstatus = true;
      let sysInfo = {};

      if (this.testDetails.config.allowAgent) {
        const system = await getSystemInfo();
        sysInfo = system.data;
      }
      if (agentdata.permission && extensionData) {
        let data = {
          topicID: "",
          testID: "",
          emailID: "",
          groupID: "",
          configID: "",
          name: "",
          stats: {
            geolocation: {},
            system: sysInfo
          },
          attemptNo: null
        };
        data.testID = this.testDetails.testID;
        data.topicID = this.testDetails.topicID;
        data.groupID = this.testDetails.groupID;
        data.emailID = this.userDetials.email;
        data.name = this.userDetials.firstName;
        data.stats.geolocation = this.statsLocation;
        data.attemptNo = this.testDetails.attemptNo;
        data.configID = this.testDetails.config.configID;
        let payload = {
          topicID: this.testDetails.topicID,
          testID: this.testDetails.testID,
          groupID: this.testDetails.groupID,
          userID: this.userID,
          attemptNo: this.testDetails.attemptNo
        };
        this.$store
          .dispatch("testVUEX/postStatsData", data)
          .then((res) => {
            if (res.status == 200) {
              this.$store
                .dispatch("testVUEX/getTestResponse", payload)
                .then((res) => {
                  if (res.status == 200) {
                    window.removeEventListener(
                      "message",
                      this.windowListenerFunc()
                    );
                    this.LoaderStat=false;
                    this.$router.push({ path: "/testScreen" });
                    this.Loaderstatus = false;
                  }
                })
                .catch((err) => {
                  console.log(err);
                  this.Loaderstatus = false;
                });
            }
          })
          .catch((err) => {
            console.log(err);
            this.Loaderstatus = false;
          });
        this.$store.commit("questionVUEX/initializetestresponseQuesWithAns");
      } else {
        this.Loaderstatus = false;
      }
    },
    leave() {
      if(localStorage.getItem("fromCandidate")){
        window.close('','_parent','');
      }
      localStorage.clear();
      this.$router.push({
        path: "/",
        query: {
          uuid: this.uuid
        }
      });
    },
    mobileTest() {
      this.LoaderStat=true;
      this.candidateteststatsPost();
      this.goToTestDetails({ permission: true }, true);
    }
  }
};
export default dosDonts;
