<template>
  <div class="mobileScreen-main-container">
    <div v-if="LoaderStat" class="relativeclass">
      <div class="spinnerstyle">
        <Spinner />
      </div>
    </div>
    <MobileHeader />
    <div
      class="mobileScreen-main-innerContainer d-flex flex-column justify-content-between"
    >
      <div style="height: 90%;">
        <div class="mobileScreen-TD-head ml-3 mt-3">
          Do's and Dont's
        </div>
        <div class="mobileScreen-DND-mainBody">
          <ul>
            <li
              class="DND-text-font  mt-2 mr-3"
              v-for="(item, index) in testDetails.config.dosAndDonts"
              :key="index"
            >
              {{ item.value }}
            </li>
          </ul>
        </div>
      </div>
      <div class="mb-5 ml-3 mob-checkbox DND-checkbox-font">
        <b-form-checkbox name="checkbox-1" value="accepted" v-model="checked">
          I accept all the above mentioned terms and conditions
        </b-form-checkbox>
      </div>
      <div
      class="mobileScreen-TD-footer d-flex align-items-center justify-content-between bluebtns"
    >
      <b-button
        class="mobileScreen-leaveBtn mr-3"
        @click="$bvModal.show('mobileLeaveTest')"
        >Leave Test</b-button
      >
      <b-button
        class="mobileScreen-startBtn"
        :disabled="!checked"
        @click="mobileTest()"
      >
        Proceed</b-button
      >
    </div>
    </div>


    <b-modal id="mobileLeaveTest" centered hide-footer hide-header class="p-0">
      <div class="d-flex align-items-center justify-content-center flex-column">
        <div>
          <img
            src="../../assets/mobile-newUI-imgs/new-warning-icon.svg"
            alt=""
          />
        </div>
        <div class="mt-4 mobileScreen-modal-head">
          Alert!
        </div>
        <div class="mt-3 mobileScreen-modal-subhead text-center">
          Are you sure you want to leave the test ? <br />
          **Please note this action cannot be undone**
        </div>
        <div
          class="mt-4 bluebtns d-flex align-items-center justify-content-center flex-column w-100"
        >
          <div class="w-50">
            <b-button
              class="mobileScreen-startBtn w-100"
              @click="$bvModal.hide('mobileLeaveTest')"
              >Cancel</b-button
            >
          </div>
          <div class="w-50">
            <b-button @click="leave()" class="mobileScreen-leaveBtn mt-3 w-100"
              >Leave Test</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import MobileHeader from "./MobileHeader.vue";
import dosDonts from "../../mixins/dosDonts";
export default {
  mixins: [dosDonts],
  data() {
    return {
      dos: 5,
      LoaderStat: false,
    };
  },
  components: {
    MobileHeader,
  },
};
</script>
<style>
.mob-checkbox .custom-control-label::before {
  position: absolute;
  top: 3px;
  left: -1.5rem;
  display: block;
  width: 15px;
  height: 15px;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #20639b solid 1px;
}

.mob-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #20639b;
  background-color: #20639b;
  top: 3px;
}

.mob-checkbox .custom-control-label {
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #344054;
}
.mob-checkbox .custom-control-label::after {
  top: 3px;
}
.mdds-detail-container ::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #eeeeee;
}
.mdds-detail-container ::-webkit-scrollbar-thumb {
  background: #72738e;
  border-radius: 10px;
}
.mdds-detail-container ::-webkit-scrollbar {
  width: 5px;
}
.mobileScreen-TD-footer .b-overlay {
  margin-left: 26px;
}
</style>
